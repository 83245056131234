import React, {useState, useEffect} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const [applicationName, setApplicationName] = useState(props.applicationName)
  const [id, setId] = useState(props.id)
  const [customSideNavitems, setCustomSideNavitems] = useState([])

  const history = useHistory();

  useEffect(() =>{
    setApplicationName(props.applicationName)
  },[props.applicationName])

  useEffect(() =>{
    setCustomSideNavitems(props.customSideNavitems)
  },[props.customSideNavitems])
  
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: `/App/${id}`, text: `${(applicationName) ? applicationName : ''}` }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={customSideNavitems}
    />
  );
}