import { API, graphqlOperation } from "aws-amplify";
import { TEMPLATE_APP, QSC_APP_SPECIFIC_FIELDS } from "../Constants";
import { FRISCO_DROPDOWN_METADATA_KEYS, PROCESS_KEYS } from "src/Ateam-BI/Frisco/Constants";
import { FLEXICO_DROPDOWN_METADATA_KEYS, FLEXICO_TEMPLATE_KEYS } from "src/Ateam-BI/FlexiCo/Constants";


export const queryFieldValues = async() => {
    let mQry = `
    query MyQuery {
        getAppMetadataFieldValuesV2(types: []) {
          type
          visibility
          values {
            label
            value
            value_id
          }
        }
      }  
        `;

    let response = await API.graphql(graphqlOperation(mQry));
    return response.data.getAppMetadataFieldValuesV2;
}

export const getFieldTypeLabelMappers = () => {
    let field_types_and_selection_mapper = {}
    field_types_and_selection_mapper['Organization'] = TEMPLATE_APP.ORGANIZATION
    field_types_and_selection_mapper['Data source details'] =  TEMPLATE_APP.DATASOURCE
    field_types_and_selection_mapper['Region'] =  TEMPLATE_APP.REGION
    field_types_and_selection_mapper['Use case type'] =  TEMPLATE_APP.USE_CASE
    field_types_and_selection_mapper['Automation tools'] =  TEMPLATE_APP.AUTOMATION_TOOLS
    field_types_and_selection_mapper['Input source type'] =  TEMPLATE_APP.INPUT_SOURCE_TYPE
    field_types_and_selection_mapper['Ledger impact'] =  TEMPLATE_APP.LEDGER_INFO
    field_types_and_selection_mapper['Run frequency'] =  TEMPLATE_APP.CADENCE
    field_types_and_selection_mapper['Developer team'] =  TEMPLATE_APP.DEVELOPER_TEAM
    field_types_and_selection_mapper['Accounting team'] =  TEMPLATE_APP.ACCOUNTING_TEAM
    field_types_and_selection_mapper['Output type'] =  TEMPLATE_APP.OUTPUT
    field_types_and_selection_mapper['Financial relevance'] =  TEMPLATE_APP.FINANCIAL_RELEVANCE
    field_types_and_selection_mapper['Financial year impact'] =  TEMPLATE_APP.FINANCIAL_YEAR_IMPACT
    field_types_and_selection_mapper['QSC_DATASOURCE'] =  QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE
    field_types_and_selection_mapper['MIGRATION_STATUS'] =  QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS
    field_types_and_selection_mapper['DOWNSTREAM_USE'] =  QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE
    field_types_and_selection_mapper['Status'] =  TEMPLATE_APP.STATUS
    field_types_and_selection_mapper['Launch Date'] =  TEMPLATE_APP.LAUNCH_QQ_YY
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.TEAMS] =  PROCESS_KEYS.team
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.BUSINESSES] =  PROCESS_KEYS.business
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.ACTIONS] =  PROCESS_KEYS.action
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.ENVIRONMENTS] =  PROCESS_KEYS.environment
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.FILE_TYPES] =  PROCESS_KEYS.file_types
    field_types_and_selection_mapper[FRISCO_DROPDOWN_METADATA_KEYS.QUARTER] =  FRISCO_DROPDOWN_METADATA_KEYS.QUARTER
    field_types_and_selection_mapper[FLEXICO_DROPDOWN_METADATA_KEYS.FILE_TYPES] =  `flexico_${FLEXICO_TEMPLATE_KEYS.file_types}`
    field_types_and_selection_mapper[FLEXICO_DROPDOWN_METADATA_KEYS.TRIGGER_TYPES] =  `flexico_${FLEXICO_TEMPLATE_KEYS.trigger_type}`

    return field_types_and_selection_mapper
}

export const getLabelToFieldTypeMappers = () => {
  let field_types_mapper = {}
  let field_type_label_mapper = getFieldTypeLabelMappers()
  Object.keys(field_type_label_mapper).forEach(key=>{
    field_types_mapper[field_type_label_mapper[key]] = key
  })
  return field_types_mapper
}

export const getEditableFields = async() => {
    let fieldMetadata = {}
    fieldMetadata[TEMPLATE_APP.APP_NAME] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.APP_DESCRIPTION] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.DEVELOPER_TEAM] = {  editable: true}
    fieldMetadata[TEMPLATE_APP.PROCESS_OWNER] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.URL] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.DEVELOPER_TEAM] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.DEVELOPER_TEAM][TEMPLATE_APP.TEAM_SUB.TEAM] = {  editable: true }
    fieldMetadata[TEMPLATE_APP.DEVELOPER_TEAM][TEMPLATE_APP.TEAM_SUB.EMAIL] = { visibility: true, editable: true}
    fieldMetadata[TEMPLATE_APP.ACCOUNTING_TEAM] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.ACCOUNTING_TEAM][TEMPLATE_APP.TEAM_SUB.TEAM] = {  editable: true }
    fieldMetadata[TEMPLATE_APP.ACCOUNTING_TEAM][TEMPLATE_APP.TEAM_SUB.EMAIL] = { visibility: true, editable: true}
    fieldMetadata[TEMPLATE_APP.VP] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.PROCESS_OWNER] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.GRC_CONTROL_OWNER] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.ORGANIZATION] = {  editable: true}
    fieldMetadata[TEMPLATE_APP.ARD] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.FLASH_INFO_DESCRIPTION] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.DATASOURCE] = { editable: true }
    fieldMetadata[TEMPLATE_APP.INPUT_SOURCE_TYPE] = { editable: true }
    fieldMetadata[TEMPLATE_APP.LAUNCH_MCM] = { editable: true }
    fieldMetadata[TEMPLATE_APP.LAUNCH_QQ_YY] = {  editable: true}
    fieldMetadata[TEMPLATE_APP.LEDGER_INFO] = {  editable: true}
    fieldMetadata[TEMPLATE_APP.OUTPUT] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.FINANCIAL_RELEVANCE] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.FINANCIAL_YEAR_IMPACT] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.OUTPUT][TEMPLATE_APP.OUTPUT_SUB.TYPE] = { editable: true }
    fieldMetadata[TEMPLATE_APP.PDD] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.ANNUAL_MER_SAVINGS] = {  editable: true }
    fieldMetadata[TEMPLATE_APP.PROCESS_MJE_COUNT] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.CADENCE] = { editable: true }
    fieldMetadata[TEMPLATE_APP.AUTOMATION_TOOLS] = {  editable: true}
    fieldMetadata[TEMPLATE_APP.REGION] = { editable: true}
    fieldMetadata[TEMPLATE_APP.STATUS] = {editable: true }
    fieldMetadata[TEMPLATE_APP.TDD] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.USE_CASE] = {  editable: true  }
    fieldMetadata[TEMPLATE_APP.SOURCE_DATA_TABLES] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.SIM_TICKET_URL] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.PERMISSION] = { visibility: true, editable: true }
    fieldMetadata[TEMPLATE_APP.CHART_OF_ACCOUNT] = { visibility: true, editable: true }
    fieldMetadata[PROCESS_KEYS.action] = { visibility: true, editable: true }
    fieldMetadata[PROCESS_KEYS.team] = { visibility: true, editable: true }
    fieldMetadata[PROCESS_KEYS.business] = { visibility: true, editable: true }
    fieldMetadata[`flexico_${FLEXICO_TEMPLATE_KEYS.file_types}`] = { visibility: true, editable: true }
    fieldMetadata[`flexico_${FLEXICO_TEMPLATE_KEYS.trigger_type}`] = { visibility: true, editable: true }
    fieldMetadata[QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS] = { visibility: true, editable: true }
    fieldMetadata[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE] = { visibility: true, editable: true }
    fieldMetadata[QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE] = { visibility: true, editable: true }



    let metaData = await queryFieldValues();
    let mapper = getFieldTypeLabelMappers()
    metaData.forEach((value) => {
        if(!fieldMetadata.hasOwnProperty(mapper[value.type])){
            fieldMetadata[mapper[value.type]] = {}
        }
        fieldMetadata[mapper[value.type]]['visibility'] = value?.visibility
        fieldMetadata[mapper[value.type]]['values'] = value?.values
    })
    return fieldMetadata;
}

export const sortQuarters = (values) => {
  values.sort(function (a, b) {
    let split_a = a.label.split(' ')
    let temp = split_a[0]
    split_a[0] = split_a[1]
    split_a[1] = temp
    let altered_a = split_a.join(' ')

    let split_b = b.label.split(' ')
    temp = split_b[0]
    split_b[0] = split_b[1]
    split_b[1] = temp
    let altered_b = split_b.join(' ')
    return altered_a.localeCompare(altered_b);
});

}