import React from "react";
import {
    RadioGroup
} from "@amzn/awsui-components-react";

const CustomRadioGroup = (props) => {
    
    return (
        <>
            <RadioGroup
                onChange={({ detail }) => props.updateValue(props.field, detail.value, props.subKey)}
                value={props.getValue(props.field, props.subKey)}
                items={props.getOptions(props.field, props.subKey)}
            />
        </>



    )
}

export default CustomRadioGroup;