import React, { useState, useEffect } from "react";
import { glossary, TEMPLATE_APP,QSC_APP_SPECIFIC_FIELDS,  TEMPLATE_APP_TEAM } from "./Constants";
import { setCurrentAppId } from "../../store/userAuthSlice";
import SideNav from "./SideNav";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Input,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Table,
  Modal,
  Spinner,
  Badge,
  StatusIndicator,
  HelpPanel,
  Flashbar,
  Alert
} from "@amzn/awsui-components-react";

import "../../index.scss";
import "./template.scss"
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import favLogo from '../../images/star-regular-brown-for-home.svg'
import favLogoSolid from '../../images/star-solid-brown-for-home.svg'
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import CustomSelect from "./components/CustomSelect";
import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomInput from "./components/CustomInput";
import CustomMultiInput from "./components/CustomMultiInput";
import CustomRadioGroup from "./components/CustomRadioGroup";
import CustomSelectAlias from "./components/CustomSelectAlias";
import CustomSelectMultiAlias from "./components/CustomSelectMultiAlias";

import { getEditableFields } from "./Utils/FormMetadata";
import { canAddEditDeleteApplication } from "./Utils/Permissions";
import { addApp, updateApp, deleteAppWithApproval, getSubLinks, getApprovalsForApp, getProcessForAppId, getQSCApp, createAuditLog, getLogicoProcessesForAppId, updateQSCApp } from "./Utils/AppActivityUtils"
import { TEMPLATES, APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE } from 'src/Ateam-BI/Templates/Constants';
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { createApprovalDraft, startApproval, startWASApproval } from "src/utilities/ApprovalAPIs";
import { loadFlexicoTemplates } from "src/utilities/FlexicoAPIs";
import { getSuggestedLogins } from "./Utils/AppActivityUtils";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
  const dispatch = useDispatch();
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);

  //UseCase Details
  const [usecaseName, setUsecaseName] = useState('');
  const [usecaseDescription, setUsecaseDescription] = useState();
  const [usecaseStatus, setUsecaseStatus] = useState();
  const [usecaseComments, setUsecaseComments] = useState();
  const [usecaseDevComments, setUsecaseDevComments] = useState();
  const [usecaseLedgerImpact, setUsecaseLedgerImpact] = useState([]);
  const [financialRelevance, setFinancialRelevance] = useState();
  const [usecaseRunFrequency, setUsecaseRunFrequency] = useState([]);
 
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteAppName, setDeleteAppName] = useState('');



  const [toolsOpen, setToolsOpen] = useState(false);

  const [flashBarComments, setFlashBarComments] = useState();
  const [flashBarDevComments, setFlashBarDevComments] = useState();
  const [id, setId] = useState(props?.id);
  const [customSideNavitems, setCustomSideNavitems] = useState(props?.customSideNavitems)
  const [renderJson, setRenderJson] = useState(props?.renderJson)
  const [renderJsonEdited, setRenderJsonEdited] = useState(props?.renderJson)

  const [isFavorite, setIsFavorite] = useState(false);
  const [favDisabled, setFavDisabled] = useState(false);
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNewApp, setIsNewApp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [canDelete, setCanDelete] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAdd, setCanAdd] = useState(false)
  const [enableEditAction, setEnableEditAction] = useState(false)
  const [subLinks, setSubLinks] = useState([])
  const [modalMessage, setModalMessage] = useState('')
  const [subProcesses, setSubProcesses] = useState([])
  const [logicoProcesses, setLogicoProcesses] = useState([])

  const [updateComments, setUpdateComments] = useState("")
  const [modalError, setModalError] = useState("")
  const [alertErrorMessage, setAlertErrorMessage] = useState(null)
  const [flexicoTemplates, setFlexicoTemplates] = useState();
  const [suggestedLogins, setSuggestedLogins] = useState([])


 
  useEffect(() => {
    setInitView()
    checkFavorite(id)
    return () => {
      // this now gets called when the component unmounts
    };
  }, [id]);

  useEffect(() => {
    checkFavorite(id)
  }, [userId]);

  useEffect(() => {

    setFlashBarComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarComments([]),
      content: (
        <>
          {usecaseComments}
        </>
      ),
      id: "comments"
    }])

  }, [usecaseComments])

  useEffect(() => {
    setFlashBarDevComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarDevComments([]),
      content: (
        <>
          {usecaseDevComments}
        </>
      ),
      id: "comments"
    }])

  }, [usecaseDevComments])

  useEffect(() => {
    loadSideNav(subProcesses)
  }, [subProcesses, canEdit, subLinks, logicoProcesses, flexicoTemplates])

  useEffect(() => {
    // clean data
    let jsonData = cleanData(props.renderJson)
    setRenderJson(jsonData)
    setRenderJsonEdited(jsonData)
  }, [props.renderJson])


  useEffect(() => {
    loadView()
    setRenderJsonEdited(renderJson)
  }, [renderJson])

  

  useEffect(() => {
    checkIfFormIsSubmittable()
  }, [renderJsonEdited])
  const mandatoryFieldListener = (key, enableUpdate) => {
    mandatoryFieldsMissing[key] = enableUpdate
  }

  const setInitView = async () => {
    if(id){
      setSubLinks(getSubLinks(id))
      dispatch(setCurrentAppId(id));
      loadData();
      loadFlexico(id)
      setIsEditMode(false)
      setIsNewApp(false)
    }else{
      setIsNewApp(true)
    }
    loadEditableFields();
    let accessPerms;
    if(id && APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE[currentStage?.toUpperCase()].includes(id)) {
      accessPerms = await canAddEditDeleteApplication(userId, id, currentStage)
    }
    else {
      accessPerms = await canAddEditDeleteApplication(userId, TEMPLATES.QSC, currentStage)
    }
      setCanDelete(accessPerms?.delete)
      setCanEdit(accessPerms?.edit)
      setCanAdd(accessPerms?.add)
    if (!id && accessPerms?.add) {
        setIsNewApp(true)
        setShowLoadingIcon(false);
        (!isEditMode) ? setIsEditMode(true) : ''
      }

      // !id means isNewApp=true. Sometimes the useeffect take time, so relying on id. id is a path param
    if(!id && !accessPerms?.add){
      setAlertErrorMessage(
        {
          "header":"Access denied",
          "message": "No access to add an application"
        }
      )
    }else{
      setAlertErrorMessage(null)
    }
  }

  const loadFlexico = (app_id) => {
    loadFlexicoTemplates(app_id, function onSuccess(result){
      setFlexicoTemplates(result.data.listFlexicoTemplates)
    }, function onFailure(error){

    })
  }

  const cleanData = (input) => {
    // some cases, DB responds with null if there is no acc team
    if(input.hasOwnProperty(TEMPLATE_APP.ACCOUNTING_TEAM) && input[TEMPLATE_APP.ACCOUNTING_TEAM]&& input[TEMPLATE_APP.ACCOUNTING_TEAM].length > 0 && !input[TEMPLATE_APP.ACCOUNTING_TEAM][0][TEMPLATE_APP_TEAM.TEAM]){
      input[TEMPLATE_APP.ACCOUNTING_TEAM] = null
    }
    return input
  }
  
  const loadEditableFields = async () => {
    let res = await getEditableFields()
    setFieldMetadata(res)
  }

  const loadSideNav = (subProcesses) => {

    let sideNavSubLinks = []
    subLinks?.forEach((subLink) => {
      sideNavSubLinks.push({
        "type": "link",
        "text": subLink.label,
        "href": subLink.url
      })
    })



    if (canEdit) {
      
    }

    let sideNavLinks = []
    if(id){
     
      sideNavLinks.push({
        "type": "link",
        "text": "Migration Details",
        "href": `/App/${id}/QSCAppAdditionalDetails`
    })
   
    }
    

    if (sideNavLinks.length > 0) {
      sideNavLinks = sideNavLinks.concat(props?.customSideNavitems)
      setCustomSideNavitems(sideNavLinks)
    }
  }

  const getCurrentTime = () => {
    return Date.now();
  }

  const loadView = () => {
    setUsecaseName(renderJson[TEMPLATE_APP.APP_NAME]);
    setUsecaseDescription(renderJson[TEMPLATE_APP.APP_DESCRIPTION]);
    setUsecaseStatus(renderJson[TEMPLATE_APP.STATUS]);
    setUsecaseComments(renderJson[TEMPLATE_APP.FLASH_INFO_DESCRIPTION]);
    setUsecaseDevComments(renderJson[TEMPLATE_APP.DEV_ALERT_MESSAGES]);
    setUsecaseLedgerImpact(renderJson[TEMPLATE_APP.LEDGER_INFO]);
    setUsecaseRunFrequency(renderJson[TEMPLATE_APP.CADENCE]);
    setFinancialRelevance(renderJson[TEMPLATE_APP.FINANCIAL_RELEVANCE]);


   

    setShowLoadingIcon(false);
  }

  const loadData = async () => {
    if (!isNewApp && renderJson && Object.keys(renderJson).length > 0) {
      setShowLoadingIcon(true);
      if(renderJson.hasOwnProperty('processes')){
        // setSubProcesses(renderJson['processes'])
        // loadSideNav(renderJson['processes'])
      }else{
        let response = await getQSCApp(id)
        if (response?.data?.getQSCApp) {
          // setSubProcesses(response.data.getProcessesForAppIDV2)
          // loadSideNav(response.data.getProcessesForAppIDV2)
        }
      }

      let response = await getLogicoProcessesForAppId(id)
        if (response?.data?.getLogicoProcessesForAppID) {
          setLogicoProcesses(response.data.getLogicoProcessesForAppID)
        }
      loadView()
      setShowLoadingIcon(false);
    }
  };



  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }

  const isEditable = (fieldName, subKey = null) => {
    // if (subKey) {
    //   return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['editable'])
    // }
    // else {
    //   return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['editable'])
    // }

    if (subKey) {
        return isNewApp || (isEditMode)
      }
      else {
        return isNewApp || (isEditMode)
      }

  }

  const getSelectFieldValues = (fieldName, subKey = null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      if(fieldName === TEMPLATE_APP.STATUS && (usecaseStatus === 'Active' || usecaseStatus === 'Deprecated') && !isNewApp) {
        const index = fieldMetadata[fieldName]['values'].findIndex((option) => (option.value === 'WIP'))
        fieldMetadata[fieldName]['values'][index]["disabled"] = true
        fieldMetadata[fieldName]['values'][index]["disabledReason"] = 'Cannot update from "Active" or "Deprecated" status to "WIP".'
      } else if(fieldName === TEMPLATE_APP.STATUS && isNewApp) {
        fieldMetadata[fieldName]['values'].forEach((item) => {
          if(item.value === 'Active' || item.value === 'Deprecated') {
            item["disabled"] = true
            item["disabledReason"] = '"Active" and "Deprecated" statuses are disabled when creating an application, please select "WIP".'
          }
        })
      }
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    } else {
      return []
    }
  }

  const getMultiInputFieldValues = (fieldName) => {
    if (renderJsonEdited && renderJsonEdited[fieldName]) {
      return renderJsonEdited[fieldName]
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey = null) => {
    if (subKey) {
      if (!renderJsonEdited.hasOwnProperty(fieldName)) {
        renderJsonEdited[fieldName] = {}
      }
      renderJsonEdited[fieldName][subKey] = value
    } else {
      renderJsonEdited[fieldName] = value
    }
    setRenderJsonEdited(JSON.parse(JSON.stringify(renderJsonEdited)))
  }

  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if (renderJsonEdited.hasOwnProperty(fieldName)) {
        return renderJsonEdited[fieldName][subKey]
      } else {
        return null
      }
    } else {
      return renderJsonEdited ? renderJsonEdited[fieldName] : null
    }
  }

  const getRadioYesOrNoOptions = () => {
    return [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
    ]
}

const getYesOrNoBasedOnBoolean = (booleanValue) => {

    if(booleanValue === true) {
        return "Yes"
    } else if(booleanValue === false) {
        return "No"
    } else {
        return "-"
    }
}

const getSuggestedLoginsCaller = async (fragment, size = 10) => {

    const response = await getSuggestedLogins(fragment, size)
    const data = JSON.parse(response.data.getSuggestedLogins)
    let suggestions = data["suggestions"]
    suggestions = suggestions.map((login) =>
    ({
        label: login?.toLowerCase(),
        value: login?.toLowerCase()
    }))
    setSuggestedLogins(suggestions)
    return suggestions
}

  const getMultiSelectedOptions = (fieldName) => {
    let arr = renderJsonEdited[fieldName]
    let selectedOptions = []
    if (arr) {
      arr.forEach((item) => {
        selectedOptions.push({ label: item, value: item })
      })
    }
    return selectedOptions
  }

  const cancelEdition = () => {
    setErrorMessage("")
    setIsEditMode(false)
    setRenderJsonEdited(renderJson)
  }

  const editApplication = () => {
    setIsEditMode(true)
    setRenderJsonEdited(renderJson)
  }

  const checkIfNull = (data, field, missingFields) => {
    if (!data.hasOwnProperty(field) || !data[field]) {
      missingFields.push(field)
    }
  }
  const getMissingFields = (data, fields) => {
    let missingFields = []
    fields.forEach((field) => {
      if (typeof field == 'string') {
        checkIfNull(data, field, missingFields)
      } else {
        if (field?.mainKey) {
          field.subKeys.forEach(subKey => {
            data[field.mainKey]?.forEach((item) => {
              checkIfNull(item, subKey, missingFields)
            })
          })
        }
      }
    })
    return missingFields
  }


  const deleteApplication = () => {
    if(updateComments){
      setShowLoadingIcon(true)
    setErrorMessage("")
    setModalMessage("")
    setUpdateComments("")
    dismissDeleteDialog()
    getApprovalsForApp(id, async function onSuccess(response) {
      if (response.data.getApprovalForAppV2?.length) {
        //error
        setShowLoadingIcon(false)
        setErrorMessage("")
        setModalMessage(`This app is already scheduled for deletion. Approval id : ${response.data.getApprovalForAppV2[0].approval_id}`)
      } else {
        createAuditLog(`APP#${id}`, `DEL_REQ#${getCurrentTime()}`, updateComments, JSON.stringify({renderJson}), JSON.stringify({}), "DEL REQ", userId)
        let resp = await startWASApproval(
          {
            userAlias: userId,
            stage: currentStage,
            appId: id,
            appName: renderJson[TEMPLATE_APP.APP_NAME],
            comments: updateComments,
            approveResponseNeeded:1
          })
        if(resp?.workflowInstanceId){
          createAuditLog(`APP#${id}`, `DEL_REQ#${resp?.workflowInstanceId}`, updateComments, JSON.stringify({renderJson}), JSON.stringify({}), "DEL REQ", userId)
          deleteAppWithApproval(id, resp.workflowInstanceId, function onSuccess(response) {
            setShowLoadingIcon(false)
            setModalMessage(`Approval request raised. Your app will be deleted once Approved. Approval ID : ${resp.workflowInstanceId}. Please save this approval ID for future references.`)
            // history.push("/ATeamBIAllProducts")
          }, function onFailure(error) {
            setShowLoadingIcon(false)
            setModalMessage(`An approval was created for deletion. But the auto app delete trigger creation has failed. Please contact admin/ raise tt with this approval ID in the description : ${resp.workflowInstanceId}`)
          })
        }else{
          setModalMessage("Could not create approval for deletion. Please try again")
        }
      }
    }, function onFailure(response) {
      setShowLoadingIcon(false)
      setErrorMessage("Something went wrong. Cannot fetch existing approvals for the app. Please contact admin/ raise a tt")
    })
    }else{
      setModalError("Please provide comments with the reason for deletion")
    }
  
  }

  const updateApplication = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(renderJsonEdited, [TEMPLATE_APP.APP_ID, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      renderJsonEdited[TEMPLATE_APP.LAST_UPDATED_BY] = userId
      renderJsonEdited[TEMPLATE_APP.TEMPLATE_ID] = TEMPLATES.QSC
      if(renderJsonEdited.hasOwnProperty("processes")){
        delete renderJsonEdited['processes']
      }
      if (updateComments) {
        createAuditLog(`APP#${id}`, `${getCurrentTime()}`, updateComments, JSON.stringify(renderJson), JSON.stringify(renderJsonEdited), "UPDATE REQ", userId)
        setUpdateComments("")
        updateQSCApp(cleanData(renderJsonEdited), function onSuccess(response) {
          setRenderJson(JSON.parse(JSON.stringify(response.data.updateQSCApp)))
          setShowLoadingIcon(false)
          cancelEdition()
          if (props?.redirectOnUpdate) {
            history.push(props.redirectOnUpdate)
          } else {
            history.push("/App/" + response.data.updateQSCApp.app_id)
          }
          setId(response.data.updateQSCApp.app_id);
        }, function onFailure(error) {
          setErrorMessage(error?.errors[0]?.message)
          setShowLoadingIcon(false)
        })
      }else {
        setShowLoadingIcon(false)
        setErrorMessage("Please provide your comments for the update")
      }
      
    }
  }

  const checkIfFormIsSubmittable = () => {
    // let missingFields = getMissingFields(renderJsonEdited,
    //   [TEMPLATE_APP.APP_ID, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME, { mainKey: TEMPLATE_APP.ACCOUNTING_TEAM, subKeys: [TEMPLATE_APP_TEAM.TEAM] },
    //   { mainKey: TEMPLATE_APP.OUTPUT, subKeys: [TEMPLATE_APP_OUTPUT.TYPE] }])
    // setEnableEditAction(missingFields.length <= 0)
  }
  const getDate = (value) => {
    var d = new Date(parseFloat(value) * 1000)
    return d.toLocaleString()
  }
  const dismissDeleteDialog = () => {
    setDeleteAppName('')
    setDeleteConfirmationModal(false)
  }
  const postProcessTeamEmails = (value) => {
    return value.replaceAll("@amazon.com", "")
  }

  const lastUpdatedContent = (<>
    {getValue(TEMPLATE_APP.LAST_UPDATED_TIME_STAMP) ? <>

      <span className="last_updated"> Last updated : <span className="bold">{getDate(getValue(TEMPLATE_APP.LAST_UPDATED_TIME_STAMP))}</span></span>
      {getValue(TEMPLATE_APP.LAST_UPDATED_BY) ? <span className="last_updated"> by  <span className="bold">{getValue(TEMPLATE_APP.LAST_UPDATED_BY)}</span></span> : ''}
    </> : ''}
  </>)

  const accountingValidation = (elements) => {
    let emptyTeam = true
    elements.forEach((element) => {
      if (!element.team) {
        emptyTeam = false
      }
    })
    return emptyTeam
  }

  const Content = (
    <>
      {showLoadingIcon ? (
        <Modal visible={showLoadingIcon}>
          <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
        </Modal>
      ) : (<ContentLayout
        headerVariant="high-contrast"
        className="content"
        header={
          <SpaceBetween size="l">
            <Header
              variant="h1"
            >

            </Header>

            {
            alertErrorMessage?<Alert
            statusIconAriaLabel="Error"
            type="error"
            header={alertErrorMessage?.header}
          >
            {alertErrorMessage?.message}
          </Alert>:''
          }
            {
              isEditable(TEMPLATE_APP.APP_NAME) ?
                <Box> <CustomInput field={TEMPLATE_APP.APP_NAME} placeholder="App name" updateValue={updateValue} getValue={getValue} required={true} mandatoryFieldListener={mandatoryFieldListener}></CustomInput> </Box> :
                <Box variant="h2">
                  {usecaseName}
                  <span></span>
                  <img className="favorite_icon" src={isFavorite ? favLogoSolid : favLogo} onClick={() => markFavorite(id)} ></img>
                  {
                    isNewApp ?
                      '' :
                      canEdit ?
                        (isEditMode) ?
                          '' :
                          <span>
                            <Button iconName="edit" variant="icon" onClick={editApplication} />
                            {canDelete ? <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : ''}
                            <Modal
                              onDismiss={dismissDeleteDialog}
                              visible={deleteConfirmationModal}
                              footer={
                                <Box float="right">
                                  <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                                    <Button disabled={deleteAppName !== renderJson[TEMPLATE_APP.APP_NAME]} variant="primary" onClick={deleteApplication}  >Confirm</Button>
                                  </SpaceBetween>
                                </Box>
                              }
                              header={
                                <React.Fragment>
                                  <Box variant="h2">
                                    Delete confirmation for '{renderJson[TEMPLATE_APP.APP_NAME]}'
                                  </Box>
                                </React.Fragment>
                              }
                            >
                              <SpaceBetween direction="vertical" size="m">
                              <Box>To confirm deletion, enter the name of the app in the text input field below</Box>
                              <Box>
                                <Input placeholder={renderJson[TEMPLATE_APP.APP_NAME]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                              </Box>
                      <Box>
                        <Textarea
                          class="description"
                          onChange={({ detail }) => setUpdateComments(detail.value)}
                          value={updateComments}
                          placeholder="Comments"
                        ></Textarea>
                      </Box>
                      <Box>
                        {modalError && (
                          <Box variant="p" color="text-status-error">
                            {modalError}
                          </Box>
                        )}
                      </Box>
                    </SpaceBetween>

                              
                              
                            </Modal>
                            <>{lastUpdatedContent}</>
                          </span>
                        :
                        <>{lastUpdatedContent}</>
                  }
                </Box>
            }
            {isEditable(TEMPLATE_APP.APP_DESCRIPTION)
              ?
              <Grid
                gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
              >
                <div> <Textarea
                  class="description"
                  onChange={({ detail }) => updateValue(TEMPLATE_APP.APP_DESCRIPTION, detail.value)}
                  value={getValue(TEMPLATE_APP.APP_DESCRIPTION)}
                  placeholder="Description"
                /> </div>
                <div> <span className="mandatory">*</span> </div>
              </Grid>

              : <Box class="description" variant="p">{usecaseDescription}</Box>
            }

            {
              isEditable(TEMPLATE_APP.STATUS)
                ?
                <CustomSelect field={TEMPLATE_APP.STATUS} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                :
                <span className="custom-home__category">
                  {usecaseStatus} &nbsp;
                  {usecaseStatus === "Active" ? (
                    <Icon
                      variant="success"
                      name="status-positive"
                      size="small"
                    ></Icon>
                  ) : usecaseStatus === "Deprecated" ? (
                    <Icon
                      variant="error"
                      name="status-negative"
                      size="small"
                    ></Icon>
                  ) : (
                    <Icon
                      variant="normal"
                      name="status-in-progress"
                      size="small"
                    ></Icon>
                  )}
                </span>
            }
            {
              isEditable(TEMPLATE_APP.FLASH_INFO_DESCRIPTION) ?
                <div className="comments">
                  <CustomInput field={TEMPLATE_APP.FLASH_INFO_DESCRIPTION} placeholder="Comments" updateValue={updateValue} getValue={getValue}></CustomInput>
                </div>
                :
                <Box> {usecaseComments ?
                  <Flashbar items={
                    flashBarComments
                  } /> : <Box></Box>
                }
                </Box>
            }
            {usecaseDevComments ?
              <Flashbar items={
                flashBarDevComments
              } /> :
              ''}
          </SpaceBetween>
        }
      >
        <SpaceBetween size="l" >
          <Grid
            gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
          >
            <SpaceBetween size="xxl">
            <Container
            >
              <SpaceBetween>
                <div className="header_menu">
                  <div className="header_left">
                    <Box variant="h1">Application Details</Box>
                  </div>
                </div>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ColumnLayout columns={2}>


                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Run Frequency"
                    >
                      {
                        isEditable(TEMPLATE_APP.CADENCE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.CADENCE}
                            updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {usecaseRunFrequency?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Financial Relevance"
                    >
                      {
                        isEditable(TEMPLATE_APP.FINANCIAL_RELEVANCE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.FINANCIAL_RELEVANCE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {financialRelevance?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>

                  </div>

                  <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Ledger Impact"
                  >
                    {
                      isEditable(TEMPLATE_APP.LEDGER_INFO) ?
                        <CustomMultiSelect field={TEMPLATE_APP.LEDGER_INFO}
                          updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                        :
                        <ul>
                          {usecaseLedgerImpact?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item}
                            </li>
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>

                <div>
                  <Box variant="h4">Financial year impact</Box>
                  {
                    isEditable(TEMPLATE_APP.FINANCIAL_YEAR_IMPACT) ?
                      <CustomSelect field={TEMPLATE_APP.FINANCIAL_YEAR_IMPACT} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                      : <Box variant="p">{getValue(TEMPLATE_APP.FINANCIAL_YEAR_IMPACT)}</Box>
                  }
                </div>

                </ColumnLayout>
              </SpaceBetween>

            </Container>
            <Container
            header={
              <Header
                variant="h2"
              >
                Other Details
              </Header>
            }
          >
            <SpaceBetween size="l">
              <ColumnLayout columns={3}>

              <div>
                  <Box variant="h4">Data Source</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) ?
                      <CustomSelect field={QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE)}</Box>
                  }
                </div>

                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="fgbsbi-gamma dataset URL(s)"
                  >
                    {
                      isEditable(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL) ?
                      <CustomMultiInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL} updateValue={updateValue}
                      getValues={getMultiInputFieldValues} ></CustomMultiInput>
                        :
                
                        <ul>
                            {getMultiInputFieldValues(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                  {item.trim()}
                              </li>
                            ))}
                          </ul>

                    }
                  </ExpandableSection>
                </div>

                <div>
                  <Box variant="h4">fgbsbi-gamma analysis URL</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL) ?
                      <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL} placeholder="fgbsbi-gamma analysis URL" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">fgbsbi-gamma dashboard URL</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL) ?
                      <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL} placeholder="fgbsbi-gamma dashboard URL" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">MER Savings</Box>
                  {
                    isEditable(TEMPLATE_APP.ANNUAL_MER_SAVINGS) ?
                      <CustomInput field={TEMPLATE_APP.ANNUAL_MER_SAVINGS} placeholder="Annual MER savings" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(TEMPLATE_APP.ANNUAL_MER_SAVINGS)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Other Benefit</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT) ?
                      <CustomInput field={QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT} placeholder="Other Benefit" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Downstream Use</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE) ?
                      <CustomSelect field={QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">GRC Sim Ticket</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET) ?
                      <CustomInput field={QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET} placeholder="Other Benefit" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET)}</Box>
                  }
                </div>



              </ColumnLayout>
            </SpaceBetween>
          </Container>

            </SpaceBetween>
            <Container
              header={
                <Header
                  variant="h2"
                >
                  Team Details
                </Header>
              }
            >

              <SpaceBetween>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ColumnLayout columns={2}>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Process Owner (s)"
                    >
                      {
                        isEditable(TEMPLATE_APP.PROCESS_OWNER) ?
                        <CustomSelectMultiAlias field={TEMPLATE_APP.PROCESS_OWNER} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} />
                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.PROCESS_OWNER)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Accounting VP"
                    >
                      {
                        isEditable(TEMPLATE_APP.VP) ?
                        <CustomSelectMultiAlias field={TEMPLATE_APP.VP} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} />
                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.VP)?.map((item) => (
                              <li key={item} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank"> {item.trim()} </Link>
                              </li>
                            ))}
                          </ul>
                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Accounting team"
                    >{
                        isEditable(TEMPLATE_APP.ACCOUNTING_TEAM) ?
                        <SpaceBetween direction="vertical">

                        <CustomSelect field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.TEAM} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                        <CustomInput field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.EMAIL}
                            placeholder="email" updateValue={updateValue} getValue={getValue} postProcess={postProcessTeamEmails}
                            info='For emails, type the alias only (without @amazon.com)'
                        ></CustomInput>
                    </SpaceBetween>
                          :
                          <div className="teams_section">
                            {getValue(TEMPLATE_APP.ACCOUNTING_TEAM) ? <Table
                              columnDefinitions={[
                                {
                                  id: "variable",
                                  header: "Team",
                                  cell: item => item.team || "-",
                                  isRowHeader: true
                                },
                                {
                                  id: "alt",
                                  header: "Alias",
                                  cell: item => item.email || "-",
                                  sortingField: "alt"
                                }
                              ]}
                              items={[getValue(TEMPLATE_APP.ACCOUNTING_TEAM)]}
                              loadingText="Loading team details"
                              sortingDisabled
                              empty={
                                <Box
                                  margin={{ vertical: "xs" }}
                                  textAlign="center"
                                  color="inherit"
                                >
                                  <SpaceBetween size="m">
                                    <b>No resources</b>
                                  </SpaceBetween>
                                </Box>
                              }
                              variant='embedded'
                            /> : ''}
                          </div>
                      }</ExpandableSection>
                  </div>
                  <div>
                  <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Developer Alias"
                    >
                      {
                        isEditable(QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS) ?
                        <CustomSelectAlias field={QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} />
                          :
                          <ul>
                            {getMultiInputFieldValues(QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>
                  

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="GRC Control Owner"
                    >
                      {
                        isEditable(TEMPLATE_APP.GRC_CONTROL_OWNER) ?
                        <CustomSelectAlias field={TEMPLATE_APP.GRC_CONTROL_OWNER} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} />
                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.GRC_CONTROL_OWNER)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="L7 Approver"
                    >
                      {
                        isEditable(QSC_APP_SPECIFIC_FIELDS.L7_APPROVER) ?
                        <CustomSelectAlias field={QSC_APP_SPECIFIC_FIELDS.L7_APPROVER} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} />
                          :
                          <ul>
                            {getMultiInputFieldValues(QSC_APP_SPECIFIC_FIELDS.L7_APPROVER)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>

                  <div>
                    <Box variant="h4">Created by</Box>
                    <Link href={`https://phonetool.amazon.com/users/${getValue(TEMPLATE_APP.CREATED_BY)}`} target="_blank"> {getValue(TEMPLATE_APP.CREATED_BY)} </Link>
                  </div>
                </ColumnLayout>
              </SpaceBetween>

            </Container>
            
            
          </Grid>
          <Container
              header={
                <Header
                  variant="h2"
                >
                  Questioniare
                </Header>
              }
            >
                <SpaceBetween size="l">
                <ColumnLayout columns={1}>

                <div>
                  <Box variant="h4">Confirmation that you have shared the Gamma analysis and dashboard to “ateam-bi“ as co-owner?</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED) ?
                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />


                      : <Box variant="p">{getYesOrNoBasedOnBoolean(getValue(QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED))}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">If MJE posting, does it affect Primary Ledger or if Secondary Ledger IN or JP or UK or DE?</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) ?
                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />


                      : <Box variant="p">{getYesOrNoBasedOnBoolean(getValue(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING))}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Please confirm if the financial impact of this dashboard is greater than 500 million dollars per year?</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) ?
                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />


                      : <Box variant="p">{getYesOrNoBasedOnBoolean(getValue(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD))}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Does this result in a journal entry or account reconciliation or FS disclosure or key control?</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) ?
                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />


                      : <Box variant="p">{getYesOrNoBasedOnBoolean(getValue(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC))}</Box>
                  }
                </div>

                  </ColumnLayout>
                  </SpaceBetween>

         </Container>
          
          

          <Box>
            {errorMessage && (
              <Box variant="p" color="text-status-error">
                {errorMessage}
              </Box>
            )}
          </Box>
          
          {
            !isNewApp && isEditMode ?
            <SpaceBetween direction="vertical" size="l">
                  <Textarea 
                  class="description"
                  onChange={({ detail }) => setUpdateComments(detail.value)}
                  value={updateComments}
                  placeholder="Comments"
                  ></Textarea>
                  <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                  {enableEditAction ? <Button variant="primary" onClick={updateApplication}  >Update</Button> :
                    <Button variant="primary" onClick={updateApplication} disabled={enableEditAction} >Update</Button>}

                  <Button onClick={cancelEdition}>Cancel</Button>
                </SpaceBetween>
              </div>
                </SpaceBetween>
              :
              ''
          }
        </SpaceBetween>
        <br />
        {
          !isNewApp && !isEditMode ?
            <SpaceBetween size="l" >
              <Feedback appId={id} pageId={id} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
            </SpaceBetween> : ''
        }

        {modalMessage ?
          <Modal
            onDismiss={() => { setModalMessage("") }}
            visible={modalMessage}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={() => { setModalMessage("") }}>Ok</Button>
                </SpaceBetween>
              </Box>
            }
          >
            <Box>{modalMessage}</Box>
          </Modal> : ''}

      </ContentLayout>)
      }

    </>
  )

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Glossary</Box>}>
      <Box>
        <ul>
          {glossary.map((item) => (
            <li key={item.label} className="list-item-data">
              <ExpandableSection
                variant="navigation"
                headerText={item.label}
              >
                {item.description}
              </ExpandableSection>
            </li>
          ))}
        </ul>
      </Box>
    </HelpPanel>
  );

  return (
    <AppLayout
      disableContentPaddings={true}
      content={Content}
      navigation={<SideNav activeHref={`/App/${id}`} id={id} applicationName={usecaseName} customSideNavitems={customSideNavitems} />}
      contentType="default"
      navigationOpen={NavOpen}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      tools={helpContent}
      toolsOpen={toolsOpen}
      onToolsChange={() => { setToolsOpen(!toolsOpen) }}
      headerVariant="high-contrast"
    />
  );
};
