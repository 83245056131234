export const formNotificationItems = (response, varMethod) => {
    let notificationBarItems = []
      JSON.parse(response.data.getNotification).forEach((notification, index) => {
        let notificationType = "info"
        if(notification.notification_type == 'FRISCO_2PR_ALIAS_VALIDATION_ERROR'){
          notificationType = "error"
        }
        notificationBarItems.push(
          {
            type: notificationType,
            dismissible: true,
            dismissLabel: "Dismiss message",
            content: notification.notification_message,
            id: `message_${index}`,
            onDismiss: () =>
            varMethod(items =>
                items.filter(item => item.id !== `message_${index}`)
              )
          }
        )
      })
      varMethod(notificationBarItems)
}