import React from 'react'
import { Alert } from '@amzn/awsui-components-react'


const ErrorComponent = (props) => {


  return (
    <div>
     <Alert
      statusIconAriaLabel="Error"
      type="error"
      header={props?.header}
    >
      {props?.message}
    </Alert>

      
    </div>
  )
}

export default ErrorComponent
