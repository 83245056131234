import { API, graphqlOperation } from 'aws-amplify';

/* 
Sample details object to pass to createApprovalDraft api (neededByInDays and priority are optional)
const details = {
    userAlias: "subbui",
    title:"Test Title from UI",
    summary:"Test Summary from UI",
    stage:"beta",
    plan: '[[[{alias: "ravsbhar", type: "PRINCIPAL"}, {alias: "marigala", type: "PRINCIPAL"}]]]',
    neededByInDays: 10,
    priority: "HIGH"

  }
 */ 
export const createApprovalDraft = async ({userAlias, title, summary, stage, plan, neededByInDays, priority}) => {

    stage = (stage === 'dev' ? 'beta' : stage)
    neededByInDays = typeof(neededByInDays) === 'undefined' ? 5 : neededByInDays
    priority = typeof(priority) === 'undefined' ? "LOW" : priority

    let mQry = `mutation MyMutation {
        createApprovalDraft(stage: "${stage}", summary: "${summary}", title: "${title}", user_alias: "${userAlias}", plan: ${plan},priority: "${priority}", needed_by_in_days: ${neededByInDays}) {
          approval_id
          reference_id
        }
      }
    `
    let response = await API.graphql(graphqlOperation(mQry));
    let createApprovalDraftResponse = response.data.createApprovalDraft;
    return createApprovalDraftResponse
    
}
/*
Sample details object to pass to getApprovalStatus api (all fields are mandatory)
const details = {
    userAlias: "subbui",
    stage:"beta",
    approvalId: "14911710"
  }
*/

export const getApprovalStatus = async ({approvalId, stage, userAlias}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry = `query MyQuery {
    approvalStatus(approval_id: "${approvalId}", stage: "${stage}", user_alias: "${userAlias}")
        }`;
    let response = await API.graphql(graphqlOperation(mQry));
    let approvalStatusResponse = response.data.approvalStatus;
    // console.log(approvalStatusResponse)
    return approvalStatusResponse

}


/*
Sample details object to pass to startApproval api (all fields are mandatory)
const details = {
    userAlias: "subbui",
    stage:"beta",
    approvalId: "14911710"
  }
*/

export const startWASApproval = async ({stage, userAlias, appId, appName, comments, approveResponseNeeded}) => {
    let tags = `[{key: "source_type", value: "ABC_APP_DELETE_APPROVAL"},{key: "app_id", value: "${appId}"}]`
    let approverList = `[{type: "GROUP", identifier:"ateam-abc-admin(ldapGroup)"}]`
    stage = stage === "dev" ? "beta" : stage;
    let mQry = `mutation MyMutation {
      friscoStartWorkflow2PR
      (createdBy: "${userAlias}", description: "${comments}", entity_id: "ABC_APP",
      name: "Approval for App deletion : ${appName}", stage: "${stage}", approverList: ${approverList}, tags: ${tags}, approve_responses_needed: "${approveResponseNeeded}")
    }`;
    let response = await API.graphql(graphqlOperation(mQry));
    let startApprovalResponse = response.data.friscoStartWorkflow2PR;
    return JSON.parse(startApprovalResponse)
}

export const startApproval = async ({approvalId, stage, userAlias}) => {

  stage = stage === "dev" ? "beta" : stage;
  let mQry = `query MyQuery {
  startApproval(approval_id: "${approvalId}", stage: "${stage}", user_alias: "${userAlias}")
      }`;
  let response = await API.graphql(graphqlOperation(mQry));
  let startApprovalResponse = response.data.startApproval;
  // console.log(startApprovalResponse)
  return startApprovalResponse

}

/*
Sample details object to pass to cancelApproval api (all fields are mandatory)
const details = {
    userAlias: "subbui",
    stage:"beta",
    approvalId: "14911710"
  }
*/

export const cancelApproval = async ({approvalId, stage, userAlias}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry = `query MyQuery {
    cancelApproval(approval_id: "${approvalId}", stage: "${stage}", user_alias: "${userAlias}")
        }`;
    let response = await API.graphql(graphqlOperation(mQry));
    let cancelApprovalResponse = response.data.cancelApproval;
    // console.log(cancelApprovalResponse)
    return cancelApprovalResponse

}



  