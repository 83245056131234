import React, { useState, useEffect } from "react";
import {
  Select
} from "@amzn/awsui-components-react";

const CustomSelectAlias = (props) => {
  const [options, setOptions] = useState(props.options)
  const makeSelectObject = () => {
    let value = props.getValue(props.field, props.subKey)
    if(value == null){
      return null
    }else{
      return { label: value, value: value }
    }
  }
  useEffect(() =>{
    setOptions(props.options)
  },[props.options])
  return (
    <Select
      selectedOption={makeSelectObject()}
      onChange={({ detail }) =>
        props.updateValue(props.field, [detail.selectedOption.value])
      }
      placeholder="Select Alias"
      options={options}
      filteringType="manual"
      onLoadItems ={({detail})=>{
        
        props.getOptions(detail?.filteringText)
        
      }}
    />
  )
}

export default CustomSelectAlias;


