import React, { useState, useEffect } from "react";
import {
    Select,
    Box,
    Button
} from "@amzn/awsui-components-react";

const CustomSelectMultiAlias = (props) => {
    let [rows, setRows] = useState([]);
    const [options, setOptions] = useState(props.options)

    useEffect(() => {
        setOptions(props.options)
    }, [props.options])

    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        // console.log(props,getMergedInputValues())
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const onEdit = (value, index) => {
        rows[index] = value
        setRows([...rows])
    }


    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        setRows([...rows, null])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        values.forEach(element => {
            rows.push(element)
        });
        setRows(JSON.parse(JSON.stringify(values)))
    }


    const makeSelectObject = (value) => {
        // let value = props.getValue(props.field, props.subKey)
        if (value == null) {
            return null
        } else {
            return { label: value, value: value }
        }
    }

    const getSelectItem = (value, index) => {
        return <div className='rows' key={index}>
            <div className="row" >
                <Select
                    selectedOption={makeSelectObject(value)}
                    onChange={({ detail }) => {
                        onEdit(detail?.selectedOption?.value, index)
                    }

                    }
                    placeholder="Select Alias"
                    options={options}
                    filteringType="manual"
                    onLoadItems={({ detail }) => {

                        props.getOptions(detail?.filteringText)

                    }}
                />
            </div>
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
        </div>
    }

    return (
        <Box>

            {rows ? (Array.from(rows).map((value, index) => {
                return getSelectItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomSelectMultiAlias;